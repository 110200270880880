/**
 * Site header
 */
.site-header {
    border-top: 5px solid $grey-color-light;
    border-bottom: 1px solid $grey-color-light;
    margin-bottom: 0;
    // Positioning context for the mobile navigation icon
    position: relative;
}


h1.wrapper {
    margin-bottom: 0;
}

a.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    text-align: center;
    display: block;
    color: #888;
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: 30px;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */

.postal {
    text-align: center;
    position: relative;
    margin: 2em 0;

    a {
        min-height: 70vh;
        background-position: 50% 50%;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        -webkit-filter: grayscale(100%);
        transition: all .5s ease-in-out;

        &:hover {
            -webkit-filter: grayscale(0%);
            transition: all .5s ease-in-out;
        }
    }

    @include media-query($on-palm) {
        margin: 1em 0;
    }


    span {
        flex: 0 1;
        background: rgba(255,255,255,.9);
        box-shadow: 0px 0px 2px rgba(0,0,0,.3);
        padding: .5em 0;
        display: block;
        color: #333;
        font-size: 2em;

        @include media-query($on-palm) {
            font-size: 1.6em;
        }
    }

    time {
        font-size: .45em;
        color: #666;
        font-style: italic;
        display: block;
    }
}

.post {
    max-width: 800px;
    margin: 1em auto;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @include media-query($on-palm) {
        padding: 1em;
        display: block;
    }

    & figure, & article {
        flex-basis: 0;
        min-width: 50%;
    }

    &.horizontal {
        display: block;

        @include media-query($on-palm) {
            padding: 1em 0;
        }
    }
}


.post figure {
    width: 50%;
    -webkit-flex-grow: 2;

    text-align: center;

    img {
        max-height: 80vh;
        margin: 0 auto;

        @include media-query($on-palm) {
            max-height: 50vh;
        }
    }

    figcaption {
        font-style: italic;
        color: #666;
        font-size: 12px;
    }

    @include media-query($on-palm) {
        width: auto;
        margin-right: 0;
    }
}

.horizontal figure {
    width: 100%;
}

.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;
    padding-left: 2em;

    @include media-query($on-palm) {
        padding: 1em;
    }

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}

.horizontal .post-content {
    padding-left: 0;

    @include media-query($on-palm) {
        padding: 1em;
    }
}

.post footer {
    font-style: italic;
    text-align: right;

    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 1em 0;
    margin-top: 4em;

    p {
        margin: 0 auto;
    }

    .map-attribution {
        color: #999;
        font-size: 11px;
        float: left;
        background-color: rgba(255,255,255,.5);
        padding: .2em .5em;
        margin-top: -.4em;

        a {
            color: #999;
        }
    }
}
